import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ReportePage",
    data() {
        let ss = new MainService();
        return {
            msg: "ReportePage",
            ss: ss,
            //ajax: {
            //    "url": ss.indexDocumento(),
           //     headers: ss.getToken(),
           // },
            reportes: [],
            reporte: {},
            filtro: {},
            unidadAcademicas: {},
            UnidadAcademicaElegida: {},
            //gestiones: {},
            programas:{},
            cantidad: 0,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            options: [],
            isLoading: false,
            errorBag: {},
        };
    },

    methods: {

		totalLibros() {
			this.cantidades = this.documento.length();
		},
        getPrograma() {
            this.ss.listPrograma().then(
                (result) => {
                    let response = result.data;
                    this.programas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        generarPDF() {
            var url = new URL(this.rutaApp+"api/Documento/reporteUA");

            if(this.reporte.UnidadAcademica != null){
                url.searchParams.append('UnidadAcademica', this.reporte.UnidadAcademica);
            }

            if(this.reporte.Programa != null){
                url.searchParams.append('Programa', this.reporte.Programa);
            }
            
            console.log(url.href)
            window.open(url.href);
            
        },
        realizarReporte() {
            this.$refs['frm-reporte'].show();
            this.$refs['view-reporte'].hide();
        },
        cancelReporte() {
            if (this.reporte.id) {
                this.$refs['view-reporte'].show();
            }
            this.$refs['frm-reporte'].hide();
        },
        // getReporte() {
        //     this.ss.listUnidadAcademica().then(
        //         (result) => {
        //             let response = result.data;
        //             this.unidadAcademicas = response.data;
        //         }
        //     ).catch(error => {
        //         console.log(error);
        //     });
        // }

        
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else{
            this.getPrograma();
            this.getUnidadAcademica();
        }
    }
};
